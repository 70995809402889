import axios from "axios";

const Instance = axios.create({
  headers: {
    'Cross-Origin-Opener-Policy': 'same-origin',
    'X-Frame-Options': 'SAMEORIGIN',
    'X-Content-Type-Options': 'nosniff'
  },
});

export default Instance;
