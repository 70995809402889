import axios from '../../config/axios.config'
import { SET_AUTH_DATA, SET_LOCAL_AUTH_DATA, SET_TOKEN_DATA } from './actionTypes'
import { baseUrl, ERROR_MESSAGE, TIME_OUT_MESSAGE } from '../../constants'
import { resetApp } from '.'
import { uiStartLoading, uiStopLoading } from './uiActions'
import { encrypt } from '../../helpers'

const setAuthData = (payload) => {
  return {
    type: SET_AUTH_DATA,
    payload
  }
}

const setTokenData = (payload) => {
  return {
    type: SET_TOKEN_DATA,
    payload
  }
}

export const login = (data) => {
  return async (dispatch) => {
    if (!navigator.onLine) {
      return TIME_OUT_MESSAGE
    }
    try {
      dispatch(uiStartLoading())
      const url = baseUrl + 'Authorization/validation'
      const res = await axios.post(url, { ...data, password: encrypt(data.password) })
      if (res.status === 200 && res.data.code === "00") {
        dispatch(setAuthData(res.data.data))
        dispatch(uiStopLoading())
        return null
      } else {
        dispatch(uiStopLoading())
        return res.data?.description
      }
    } catch (error) {
      dispatch(uiStopLoading())
      if (error.response?.data?.description) {
        return error.response.data.description
      }
      return ERROR_MESSAGE
    }
  }
}

export const verifyToken = (data) => {
  return async (dispatch, state) => {
    if (!navigator.onLine) {
      return TIME_OUT_MESSAGE
    }
    try {
      dispatch(uiStartLoading())
      const url = baseUrl + 'Authorization/tokenAuthentication'
      const res = await axios.post(url, { ...data })
      if (res.status === 200 && res.data.code === "00") {
        dispatch(setTokenData(state.auth.authData))
        dispatch(uiStopLoading())
        return null
      }
      else {
        dispatch(uiStopLoading())
        return res.data?.description
      }
    } catch (error) {
      dispatch(uiStopLoading())
      return "Invalid Token!!"
    }
  }
}

export const logOut = () => {
  return async (dispatch, state) => {
    if (!navigator.onLine) {
      return TIME_OUT_MESSAGE
    }
    try {
      dispatch(uiStartLoading())
      const url = baseUrl + 'Authorization/signOut'
      const res = await axios.post(url, {}, {
        headers: {
          Authorization: `Bearer ${state.auth.tokenData.token}`
        }
      })
      if (res.status === 200 && res.data.code === "00") {
        resetApp()
        return null
      } else {
        dispatch(uiStopLoading())
        return res.data?.description
      }
    } catch (error) {
      dispatch(uiStopLoading())
      resetApp()
      if (error.response?.data?.description) {
        return error.response.data.description
      }
      return ERROR_MESSAGE
    }
  }
}

export const setLocalAuthData = (payload) => {
  return {
    type: SET_LOCAL_AUTH_DATA,
    payload
  }
}

export const setBrowserXframeOption = () => {
  return async (dispatch) => {
    if (!navigator.onLine) {
      return TIME_OUT_MESSAGE
    }
    try {
      dispatch(uiStartLoading())
      const url = baseUrl + 'Initialization/SetXFrameOptions'
      const res = await axios.get(url, {
        headers: {
          'Cross-Origin-Opener-Policy': 'same-origin',
          'X-Frame-Options': 'SAMEORIGIN',
          'X-Content-Type-Options': 'nosniff',
        }
      })
      if (res.status === 200 && res.data.code === "00") {
        dispatch(uiStopLoading())
        return null
      } else {
        dispatch(uiStopLoading())
        return res.data?.description
      }
    } catch (error) {
      dispatch(uiStopLoading())
      if (error.response?.data?.description) {
        return error.response.data.description
      }
      return ERROR_MESSAGE
    }
  }
}